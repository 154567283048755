<template>
  <div class="traffic">
    <Header class="hide" />
    <Headers class="dispy" />
    <div class="bar">
      <BannerImage rulename="query_banner" />
    </div>
    <div class="w">
      <Breadcrumb :crumblist="crumbdata" class="hide" />
      <div class="tilp">
        <span class="jiaotong">崇信交通</span>
      </div>
      <div class="dec" v-html="result.cont"></div>
      <div class="biaoo">
        <span>交通工具</span>
      </div>
      <div class="info clear">
        <el-row>
          <el-col v-for="(item, index) in info" :key="item.id">
            <div v-if="index % 2 == 0">
              <router-link :to="{ path: '/tradata', query: { id: item.id } }">
                <el-col :md="12">
                  <img :src="`/jeecg-boot/${item.theLocal}`" alt="" />
                </el-col>
                <el-col :md="12">
                  <div class="right clear">
                    <p class="title">{{ item.theTitle }}</p>
                    <span class="titss">{{item.subTitle}}</span>
                    <div class="tiaotiao"></div>
                    <div class="right-text">
                      <span class="dec">
                        {{ item.theInfo }}
                      </span>
                    </div>
                  </div>
                </el-col>
              </router-link>
            </div>

            <div v-else>
              <router-link :to="{ path: '/tradata', query: { id: item.id } }">
                <el-col :md="12">
                  <div class="right clear">
                    <p class="title">{{ item.theTitle }}</p>
                    <span class="titss">{{item.subTitle}}</span>
                    <div class="tiaotiao"></div>
                    <div class="right-text">
                      <span class="dec">{{ item.theInfo }}</span>
                    </div>
                  </div>
                </el-col>
                <el-col :md="12">
                  <img :src="`/jeecg-boot/${item.theLocal}`" alt="" />
                </el-col>
              </router-link>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import Pagi from "@/components/Pagi";
import {
  loadSingleConfig,
  loadPictureConfig,
  loadVideoConfig,
} from "@/api/api";
import { getAction } from "@/api/manage";
import BannerImage from "@/components/main/BannerImage";
export default {
  created() {
    this.loadData();
    this.loadInfo();
  },
  components: {
    Header,
    Footer,
    Pagi,
    Breadcrumb,
    BannerImage,
    Headers
  },
  data() {
    return {
      result: "",
      info: [],
      crumbdata: [
        { name: "信息查询", url: "" },
        { name: "交通指南", url: "/traffic" },
      ],
    };
  },
  methods: {
    loadData() {
      loadSingleConfig({
        rule: "cx_traffic",
      }).then((res) => {
        if (res.success) {
          this.result = res.result;
        }
      });
    },
    loadInfo() {
      getAction("/jtzn/tourGuide/list").then((res) => {
        if (res.success) {
          this.info = res.result.records;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.traffic {
  .bars {
    width: 100%;
  }
  background: url(/img/white-bg.9771c1fc.png) no-repeat fixed top left;
  .tilp {
    background: url("~@/assets/image/main/29.png");
    background-repeat: no-repeat;
    background-position: 49.5% 90%; /*这个是按从左往右，从上往下的百分比位置进行调整*/
    text-align: center;
    .jiaotong {
      margin-top: 37px;
      margin-bottom: 56px;
      font-size: 22px;
      color: #6EB400;
    }
  }
  .dec {
    margin-bottom: 118px;
    margin-top: 35px;
    p {
      width: 1120px;
      font-size: 14px;
      line-height: 26px;

      color: #333333;
      margin-left: 27px;
    }
  }
  .biaoo {
    height: 52px;
    background-color: #6EB400;
    margin-bottom: 28px;
    padding-top: 14px;
    text-align: center;
    margin-left: 17px;
    span {
      font-size: 24px;
      line-height: 34px;
      color: #ffffff;
      text-align: center;
    }
  }
  .info {
    margin-left: 17px;
    img {
      width: 100%;
      height: 384px;
    }
    .right {
      width: 100%;
      height: 324px;
      background-color: #f4f4f4;
      padding-top: 59px;
      .title {
        font-size: 36px;
        line-height: 29px;

        color: #333333;
        margin-left: 57px;
      }
      .titss {
        float: left;
        margin-left: 138px;
        margin-top: -17px;
        font-size: 14px;
        line-height: 29px;

        color: #6EB400;
      }
      .tiaotiao {
        width: 68px;
        height: 4px;
        background-color: #6EB400;
        margin-top: 17px;
        margin-left: 58px;
      }
      .dec {
        font-size: 14px;
        line-height: 26px;

        color: #666666;
      }
      .right-text {
        width: 448px;
        height: 196px;
        margin-left: 57px;
        margin-top: 30px;
      }
    }
    .el-row {
      padding-bottom: 20px;
    }
    .row-pp {
      padding-bottom: 231px;
    }
    .el-col-24 {
      margin-bottom: 20px;
    }
  }
}
</style>
